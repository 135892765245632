<template>
    <div class="my-10">
        <SimpleOrganizationsTable @select="openModal"></SimpleOrganizationsTable>
        <GetMoneyModal :showModal.sync="showModal" :organization="organization"></GetMoneyModal>
    </div>
</template>

<script>
import SimpleOrganizationsTable from '@/components/user/admin/SimpleOrganizationsTable'
import GetMoneyModal from '@/components/user/admin/payments/GetMoneyModal.vue'

export default {
    name: "GetMoney",
    components: {
        SimpleOrganizationsTable, GetMoneyModal
    },
    data: () => ({
        showModal: false,
        organization: {}
    }),
    methods: {
        openModal(organization) {
            this.organization = organization
            this.showModal = true
        }
    },
    watch: {
        showModal(newVal) {
            if (newVal === false) {
                this.organization = {}
            }
        }
    }

}
</script>

<style scoped>

</style>